body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Rajdhani', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: url('./assets/img/home.png');
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rajdhani', cursive !important;
}

footer .a:hover {
  fill: '#DA4567';
}

.MuiToolbar-regular {
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wallet-button {
  color: #2c2560 !important;
  font-family: 'Rajdhani', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  background-color: black !important;
  color: #757ce8 !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiPaper-elevation1,
.MuiAlert-root,
.MuiButton-root {
  border-radius: 10px !important;
}

a.active {
  text-decoration: underline !important;
}
